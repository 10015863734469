<template>
  <div class="menu">
    <el-table :data="datapath" style="width: 100%">
      <!-- :default-expand-all="true" -->
      <el-table-column type="expand">
        <!-- 2 -->
        <template slot-scope="scope">
          <!-- <p>{{ scope.row.children }}</p>  scope.row.children[0].children    default-expand-all="true"-->
          <el-table ref="topicTable" :data="scope.row.children" style="width: 100%" :row-class-name="getRowClass" @expand-change="handledetail" :show-header="false">
            <el-table-column label="" prop="label" type="expand">
              <!-- 3 -->
              <template slot-scope="scope">
                <el-table :data="scope.row.children" style="width: 100%" :show-header="false">
                  <!-- <el-table-column label="" prop="label" type=""> -->
                  <!-- 4 -->
                  <!-- <template slot-scope="scope">
                      <el-table :data="scope.row.children" style="width: 100%" :show-header="false">
                        <el-table-column label="" prop="label"></el-table-column>
                        <el-table-column label="" prop="createTime"> </el-table-column>
                        <el-table-column label="操作" prop="createTime">
                          <template slot-scope="scope"> -->

                  <!-- <i class="el-icon-plus myPlus" @click="firstAdd(scope.row,'3')"></i> -->
                  <!-- <i class="el-icon-edit myPlus" @click="firstEait(scope.row, '3')"></i> -->

                  <!-- <i class="el-icon-delete myPlus" @click="firstDel(scope.row)"></i>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column> -->

                  <el-table-column label="" prop="label"></el-table-column>
                  <!-- <el-table-column label="" prop="createTime"> </el-table-column> -->
                  <el-table-column label="操作" prop="createTime">
                    <template slot-scope="scope">
                      <!-- <i class="el-icon-plus myPlus" @click="firstAdd(scope.row, '3')"></i> -->
                      <i class="el-icon-edit myPlus" @click="firstEait(scope.row, '2')"></i>
                      <i class="el-icon-delete myPlus" @click="firstDel(scope.row)"></i>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="" prop="label"> </el-table-column>
            <!-- <el-table-column label="" prop="createTime"> </el-table-column> -->
            <el-table-column label="操作" prop="createTime">
              <template slot-scope="scope">
                <i class="el-icon-plus myPlus" @click="firstAdd(scope.row, '2')"></i>
                <i class="el-icon-edit myPlus" @click="firstEait(scope.row, '1')"></i>
                <i class="el-icon-delete myPlus" @click="firstDel(scope.row)"></i>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="label"> </el-table-column>
      <!-- <el-table-column label="创建时间" prop="createTime"> </el-table-column> -->
      <el-table-column label="操作" prop="createTime">
        <template slot-scope="scope">
          <i class="el-icon-plus myPlus" @click="firstAdd(scope.row, '1')"></i>
          <i class="el-icon-edit myPlus" @click="firstEait(scope.row, '0')"></i>
          <i class="el-icon-delete myPlus" @click="firstDel(scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="puplcTitle" :visible.sync="addMenu" width="30%" :before-close="handleClose">
      <el-form ref="form" class="puplcForm" :model="addForm" label-width="80px">
        <el-form-item label="菜单名称">
          <el-input v-model="addForm.label"></el-input>
        </el-form-item>
        <el-form-item label="菜单路径">
          <el-input v-model="addForm.path"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addMenu = false" size="mini">取 消</el-button>
        <el-button type="primary" size="mini" @click="smbit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      datapath: [],
      addMenu: false,
      addForm: {
        label: '',
        path: '',
      },
      puplcTitle: '',
      pathInput: true,
    };
  },
  mounted() {
    this.menuData();
  },
  computed: {},
  filters: {},
  methods: {
    menuData() {
      this.$get('/rights/getAllRights').then((res) => {
        this.datapath = res.data.datas;
        //console.log(this.datapath, '454545455');
      });
    },
    // 添加
    firstAdd(data, level) {
      this.addMenu = true;
      data.levels = level;
      this.puplcTitle = '添加菜单';
      //添加置空表单
      this.addForm = JSON.parse(JSON.stringify(data));
      this.addForm.label = '';
      this.addForm.path = '';
      if (level == 1) {
        this.pathInput = false;
      } else {
        this.pathInput = true;
      }
    },
    // 编辑
    firstEait(date, level) {
      date.levels = level;
      this.addMenu = true;
      this.puplcTitle = '编辑菜单';
      this.addForm = JSON.parse(JSON.stringify(date));
      //console.log(date, '编辑菜单');
      if (level == 0) {
        this.pathInput = false;
      } else if (level == 1) {
        this.pathInput = false;
      } else {
        this.pathInput = true;
      }
    },
    getRowClass: function(row) {
      //console.log(row);
      //console.log(row.row.orderChildEntities);
      //console.log(typeof row.row.orderChildEntities);
      if (!row.row.children) {
        //判断当前行是否有子数据
        return 'row-expand-cover';
      }
    },
    handledetail(row) {
      if (!row.children) {
        this.$refs.topicTable.toggleRowExpansion(row, false);
      }
    },
    // 删除
    firstDel(data) {
      let _this = this;
      //console.log(data, '删除信息');
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$get('/rights/newDeleteRights', { id: data.id }).then((res) => {
            _this.menuData();
            _this.$message.info(res.data.msg);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 确认提交
    smbit() {
      let datas = JSON.parse(JSON.stringify(this.addForm));
      let data = {
        name: datas.label,
        label: datas.label,
        path: datas.path || '',
        parentId: datas.id,
        id: datas.id,
        level: datas.levels,
        url: datas.url,
        sort:datas.sort,
        type:datas.type,
      };
      //编辑level  parentId 判断
      //   添加 id 为 0
      if (this.puplcTitle == '编辑菜单') {
        data.level = datas.levels;
        data.parentId = datas.parentId;
        data.sort = datas.sort;
      } else {
        data.id = 0;
        delete data.type;
      }
      if (data.path == '') {
        data.path = 'page';
      }
      //console.log(data);
      if (!data.name && data.name == '') return this.$message.error('请输入菜单名称后提交');
      this.$post('/bigData/addOrUpdateRights', data).then((res) => {
        //console.log(res, '提交信息');
        if (res.data.state) {
          this.menuData();
          this.$message.success('操作成功');
        } else {
          this.$message.info('操作失败，请稍后重试！');
        }
        this.addMenu = false;
      });
    },
    getRowKeys(row) {
      //console.log(row);
      return row.id;
    },
    // 关闭监听
    handleClose() {
      this.addMenu = false;
    },
  },
};
</script>
<style lang="less" scoped>
.menu {
  height: 100%;
  overflow-y: auto;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.menu /deep/ .el-table__expanded-cell[class*='cell'] {
  padding: 0px 50px !important;
}
.menu /deep/ .el-table td,
.el-table th {
  padding: 1.11vh 0;
}
.menu .myPlus {
  cursor: pointer;
  margin-right: 3vh;
}
.menu /deep/ .el-dialog__title {
  font-size: 1.6vh;
}
.puplcForm /deep/ .el-form-item__label {
  font-size: 1.4vh;
}
</style>
<style>
.row-expand-cover td:first-child {
  visibility: hidden;
}
</style>
